<template>
  <div v-show="drawingMode">
    <div id="drawing_toolbar" :style="isImageMode ? 'margin-left: 27rem; width: calc(100% - 27rem)' : null">
      <div class="d-inline-block" id="drawing_toolbar_icons" v-if="userCanAuthorInsights">
        <b-button 
          v-b-tooltip.hover 
          title="selection tool"
          @click="turnSelectionModeOn" 
          class="mx-2 gray-button"
          :variant="selectionMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/cursor.png" class="max-icon-size"></b-img>
        </b-button>
        <b-button
          v-if="pencilMenuOpen"
          @click="turnPencilOn" 
          class="mx-2 gray-button"
          :variant="pencilDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/pencil.png"></b-img>
        </b-button>
        <b-button
          v-else
          v-b-tooltip.hover 
          title="pencil tool"
          @click="turnPencilOn" 
          class="mx-2 gray-button"
          :variant="pencilDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/pencil.png"></b-img>
        </b-button>
        <b-button 
          v-if="lineMenuOpen"
          @click="turnLineDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="lineDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/line.png"></b-img>
        </b-button>
        <b-button 
          v-else
          v-b-tooltip.hover 
          title="line tool"
          @click="turnLineDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="lineDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/line.png"></b-img>
        </b-button>
        <b-button 
          v-if="shapeMenuOpen"
          @click="turnShapeDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="shapeDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/shapes.png"></b-img>
        </b-button>
        <b-button 
          v-else
          v-b-tooltip.hover 
          title="shape tool"
          @click="turnShapeDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="shapeDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/shapes.png"></b-img>
        </b-button>
        <b-button 
          v-if="imageMenuOpen"
          @click="turnImageDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="imageDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/tree.png"></b-img>
        </b-button>
        <b-button 
          v-else
          v-b-tooltip.hover 
          title="image tool"
          @click="turnImageDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="imageDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/tree.png"></b-img>
        </b-button>
        <b-button 
          v-if="textMenuOpen"
          @click="turnTextBoxDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="textBoxDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/text.png"></b-img>
        </b-button>
        <b-button 
          v-else
          v-b-tooltip.hover 
          title="text box tool"
          @click="turnTextBoxDrawingModeOn" 
          class="mx-2 gray-button"
          :variant="textBoxDrawingMode ?
            'outline-primary bg-white border-white text-dark' :
            'outline-white border-transparent text-dark'">
          <b-img src="/drawing_toolbar_icons/text.png"></b-img>
        </b-button>
      </div>
      <div>
        <section id="pencil_menu" class="p-2 rounded-bottom" v-if="pencilMenuOpen">
          <div>
            <h6 class="d-inline">PENCIL</h6>
            <span class="float-right mt-0 close-menu">
              <b-icon icon="x" size="large" @click="closePencilMenu"></b-icon>
            </span>
          </div>
          <span 
            v-for="pencilStyleChoice in pencilStyleChoices" :key="pencilStyleChoice"
            :class="['line-type-choice d-inline-block mr-1 my-1 border rounded', pencilStyle == pencilStyleChoice ? 'selected-line-weight-choice' : '']"
            @click="setPencilStyle(pencilStyleChoice)">
            <img :src="`/drawing_tool_styles/pencil-styles/${pencilStyleChoice}.png`"/>
          </span>
          <p @click="togglePencilMenuExpanded" class="toggle-menu-expanded mt-2">
            {{ pencilMenuExpanded ? 'See Less' : 'See More'}}
          </p>
          <section v-if="pencilMenuExpanded" class="mt-1 pt-1 border-top">
            <h6>PENCIL COLOUR</h6>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
              <span 
                v-for="(colour, index) in colourChoices" :key="index"
                :class="['colour-choice rounded-circle mr-2', pencilColour == colour ? 'selected-colour-choice' : '']"
                :style="`background-color: rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`"
                @click="setPencilColour(colour)">
              </span>
            </div>
            <h6 class="mt-2">LINE WEIGHT</h6>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
            <span 
              v-for="lineWeight in lineWeightChoices" :key="`pencil-${lineWeight}`"
              :style="`height: ${lineWeight}px; padding: ${8-lineWeight/2}px 0.5rem;`"
              :class="['line-weight-choice d-inline-block mr-2 mt-1 mb-1 border rounded', pencilLineWeight == lineWeight ? 'selected-line-weight-choice' : '']"
              @click="setPencilLineWeight(lineWeight)">
            </span>
            </div>
          </section>
        </section>
        <section id="line_menu" class="p-2 rounded-bottom" v-if="lineMenuOpen">
          <div>
            <h6 class="d-inline">LINES</h6>
            <span class="float-right mt-0 close-menu">
              <b-icon icon="x" size="large" @click="closeLineMenu"></b-icon>
            </span>
          </div>
          <span 
            v-for="lineTypeChoice in lineTypeChoices" :key="lineTypeChoice"
            :class="['line-type-choice px-2 d-inline-block mr-2 mt-1 border rounded', lineType == lineTypeChoice ? 'selected-line-weight-choice' : '']"
            @click="setLineType(lineTypeChoice)">
            {{ lineTypeChoice }}
          </span>
          <h6 class="mt-2">LINE STYLE</h6>
          <span 
            v-for="lineStyleChoice in lineStyleChoices" :key="lineStyleChoice"
            :class="['line-type-choice d-inline-block mr-1 my-1 border rounded', lineStyle == lineStyleChoice ? 'selected-line-weight-choice' : '']"
            @click="setLineStyle(lineStyleChoice)">
            <img :src="`/drawing_tool_styles/line-styles/${lineStyleChoice}.png`"/>
          </span>
          <p @click="toggleLineMenuExpanded" class="toggle-menu-expanded mt-1">
            {{ lineMenuExpanded ? 'See Less' : 'See More'}}
          </p>
          <section v-if="lineMenuExpanded" class="mt-0 border-top">
            <h6 class="mt-2">LINE COLOUR</h6>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
              <span 
                v-for="(colour, index) in colourChoices" :key="index"
                :class="['colour-choice rounded-circle mr-2', lineColour == colour ? 'selected-colour-choice' : '']"
                :style="`background-color: rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`"
                @click="setLineColour(colour)">
              </span>
            </div>
            <h6 class="mt-2">LINE WEIGHT</h6>
            <span 
              v-for="lineWeight in lineWeightChoices" :key="`line-${lineWeight}`"
              :style="`height: ${lineWeight}px; padding: ${8-lineWeight/2}px 0.5rem;`"
              :class="['line-weight-choice d-inline-block mr-2 mt-1 border rounded', lineLineWeight == lineWeight ? 'selected-line-weight-choice' : '']"
              @click="setLineLineWeight(lineWeight)">
            </span>
          </section>
        </section>
        <section id="shape_menu" class="p-2 rounded-bottom" v-if="shapeMenuOpen">
          <div class="border-bottom">
            <h6 class="d-inline">SHAPE SELECTOR</h6>
            <span class="float-right mt-0 close-menu">
              <b-icon icon="x" size="large" @click="closeShapeMenu"></b-icon>
            </span>
          </div>
          <div class="mt-1 pt-2">
            <img 
              :class="['mt-0 mr-4 drawing-tool-image', selectedShape == 'square' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_shapes/square.png"
              @click="setSelectedShape('square')"/>
            <img 
              :class="['mt-0 mr-4 drawing-tool-image', selectedShape == 'circle' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_shapes/circle.png"
              @click="setSelectedShape('circle')"/>
            <img 
              :class="['mt-0 mr-4 drawing-tool-image', selectedShape == 'triangle' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_shapes/triangle.png"
              @click="setSelectedShape('triangle')"/>
            <img 
              :class="['mt-0 mr-4 drawing-tool-image', selectedShape == 'polygon' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_shapes/polygon.png"
              @click="setSelectedShape('polygon')"/>
          </div>
          <h6 class="mt-2">SHAPE STYLE</h6>
          <span 
            v-for="shapeStyleChoice in shapeStyleChoices" :key="shapeStyleChoice"
            :class="['line-type-choice d-inline-block mr-1 my-1 border rounded', shapeStyle == shapeStyleChoice ? 'selected-line-weight-choice' : '']"
            @click="setShapeStyle(shapeStyleChoice)">
            <img :src="`/drawing_tool_styles/polygon-styles/${shapeStyleChoice}.png`"/>
          </span>
          <p @click="toggleShapeMenuExpanded" class="toggle-menu-expanded mt-2">
            {{ shapeMenuExpanded ? 'See Less' : 'See More'}}
          </p>
          <section class="border-top mt-0" v-if="shapeMenuExpanded">
            <h6 class="mt-2 text-box-sub-header">BORDER</h6>
            <span 
              v-for="shapeBorderTypeChoice in shapeBorderTypeChoices" :key="shapeBorderTypeChoice"
              :class="['line-type-choice px-2 d-inline-block mr-2 mt-1 border rounded', shapeBorderType == shapeBorderTypeChoice ? 'selected-line-weight-choice' : '']"
              @click="setShapeBorderType(shapeBorderTypeChoice)">
              {{ shapeBorderTypeChoice }}
            </span>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
              <span 
                v-for="(colour, index) in colourChoices" :key="`border-${index}`"
                :class="['colour-choice rounded-circle mr-2', shapeBorderColour == colour ? 'selected-colour-choice' : '']"
                :style="`background-color: rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`"
                @click="setShapeBorderColour(colour)">
              </span>
            </div>
            <h6 class="mt-3 text-box-sub-header">BORDER OPACITY</h6>
            <b-form-input 
              class="mt-0 d-inline opacity-slider"
              type="range"
              min="0"
              max="100"
              v-model="shapeBorderOpacity"/>
            <span class="opacity-label ml-1">{{ shapeBorderOpacity }}%</span>
            <h6 class="mt-2 text-box-sub-header">FILL</h6>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
              <span 
                v-for="(colour, index) in colourChoices" :key="`fill-${index}`"
                :class="['colour-choice rounded-circle mr-2', shapeFillColour == colour ? 'selected-colour-choice' : '']"
                :style="`background-color: rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`"
                @click="setShapeFillColour(colour)">
              </span>
            </div>  
            <h6 class="mt-3 text-box-sub-header">FILL OPACITY</h6>
            <b-form-input 
              class="mt-0 d-inline opacity-slider"
              type="range"
              min="0"
              max="100"
              v-model="shapeFillOpacity"/>
            <span class="opacity-label ml-1">{{ shapeFillOpacity }}%</span>
          </section>
        </section>
        <section id="image_menu" class="p-2 rounded-bottom" v-if="imageMenuOpen">
          <div class="border-bottom">
            <h6 class="d-inline">IMAGES</h6>
            <span class="float-right mt-0 close-menu">
              <b-icon icon="x" size="large" @click="closeImageMenu"></b-icon>
            </span>
          </div>
          <div class="mt-1">
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'pine-tree' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/pine-tree.svg"
              @click="setSelectedImage('pine-tree')"/>
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'regular-tree' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/regular-tree.svg"
              @click="setSelectedImage('regular-tree')"/>
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'aerial-view-tree' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/aerial-view-tree.svg"
              @click="setSelectedImage('aerial-view-tree')"/>
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'aerial-view-tree-2' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/aerial-view-tree-2.svg"
              @click="setSelectedImage('aerial-view-tree-2')"/>
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'north' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/north.svg"
              @click="setSelectedImage('north')"/>
            <img 
              :class="['mt-0 drawing-tool-image', selectedImage == 'trees' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/trees.svg"
              @click="setSelectedImage('trees')"/>
          </div>
          <div class="mt-1">
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'arrow-1' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/arrow-1.svg"
              @click="setSelectedImage('arrow-1')"/>
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'arrow-2' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/arrow-2.svg"
              @click="setSelectedImage('arrow-2')"/>
            <img 
              :class="['mt-0 mr-3 drawing-tool-image', selectedImage == 'arrow-3' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/arrow-3.svg"
              @click="setSelectedImage('arrow-3')"/>
            <img 
              :class="['mt-0 drawing-tool-image', selectedImage == 'arrow-4' ? 'selected-drawing-tool-image' : '']"
              src="/drawing_tool_images/arrow-4.svg"
              @click="setSelectedImage('arrow-4')"/>
          </div>
        </section>
        <section id="text_menu" class="p-2 rounded-bottom" v-if="textMenuOpen">
          <div>
            <h6 class="d-inline">TEXT STYLE</h6>
            <span class="float-right mt-0 close-menu">
              <b-icon icon="x" size="large" @click="closeTextMenu"></b-icon>
            </span>
          </div>
          <span 
            v-for="textBoxStyleChoice in textBoxStyleChoices" :key="textBoxStyleChoice"
            :class="['line-type-choice d-inline-block mr-2 my-1 border rounded', textBoxStyle == textBoxStyleChoice ? 'selected-line-weight-choice' : '']"
            @click="setTextBoxStyle(textBoxStyleChoice)">
            <img :src="`/drawing_tool_styles/text-styles/${textBoxStyleChoice}.png`"/>
          </span>
          <p 
            @click="toggleTextBoxMenuExpanded" 
            :class="['toggle-menu-expanded', textBoxMenuExpanded ? 'border-bottom' : '']">
            {{ textBoxMenuExpanded ? 'See Less' : 'See More'}}
          </p>
          <section v-if="textBoxMenuExpanded" class="mt-0">
            <b-dropdown 
              :text="textFont"
              class="mt-0 drawing-dropdown border my-2 rounded mr-2"
              size="sm"
              variant="outline">
              <b-dropdown-item @click="setTextFont('Inter')">Inter</b-dropdown-item>
              <b-dropdown-item @click="setTextFont('Times New Roman')">Times New Roman</b-dropdown-item>
              <b-dropdown-item @click="setTextFont('Courier New')">Courier New</b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              :text="textSize"
              class="mt-0 drawing-dropdown border my-2 rounded"
              size="sm"
              variant="outline">
              <b-dropdown-item @click="setTextSize('12')">12</b-dropdown-item>
              <b-dropdown-item @click="setTextSize('16')">16</b-dropdown-item>
              <b-dropdown-item @click="setTextSize('24')">24</b-dropdown-item>
            </b-dropdown>
            <div class="mb-2 mt-0">
              <span>
                <b-icon 
                  :class="['text-icon border rounded p-1 mr-2', textItalic ? 'selected-text-icon' : '']" 
                  icon="type-italic"
                  @click="toggleTextItalic">
                </b-icon>
              </span>
              <span>
                <b-icon
                  :class="['text-icon border rounded p-1 mr-2', textBold ? 'selected-text-icon' : '']"  
                  icon="type-bold"
                  @click="toggleTextBold">
                </b-icon>
              </span>
              <span>
                <b-icon 
                  :class="['text-icon border rounded p-1 mr-2', textUnderline ? 'selected-text-icon' : '']" 
                  icon="type-underline"
                  @click="toggleTextUnderline">
                </b-icon>
              </span>
              <span>
                <b-icon 
                  :class="['text-icon border rounded p-1 mr-2', textAlignment == 'left' ? 'selected-text-icon' : '']" 
                  icon="text-left"
                  @click="setTextAlignment('left')">
                </b-icon>
              </span>
              <span>
                <b-icon
                  :class="['text-icon border rounded p-1 mr-2', textAlignment == 'center' ? 'selected-text-icon' : '']" 
                  icon="text-center"
                  @click="setTextAlignment('center')">
                </b-icon>
              </span>
              <span>
                <b-icon
                  :class="['text-icon border rounded p-1 mr-2', textAlignment == 'right' ? 'selected-text-icon' : '']" 
                  icon="text-right"
                  @click="setTextAlignment('right')">
                </b-icon>
              </span>
            </div>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
              <span 
                v-for="(colour, index) in colourChoices" :key="index"
                :class="['colour-choice rounded-circle mr-2', textColour == colour ? 'selected-colour-choice' : '']"
                :style="`background-color: rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`"
                @click="setTextColour(colour)">
              </span>
            </div>
            <h6 class="mt-2 border-top pt-3">TEXTBOX STYLE</h6>
            <h6 class="mt-2 text-box-sub-header">BORDER COLOUR</h6>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
              <span 
                v-for="(colour, index) in colourChoices" :key="`border-${index}`"
                :class="['colour-choice rounded-circle mr-2', textBoxBorderColour == colour ? 'selected-colour-choice' : '']"
                :style="`background-color: rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`"
                @click="setTextBoxBorderColour(colour)">
              </span>
            </div>
            <h6 class="mt-2 text-box-sub-header">BORDER OPACITY</h6>
            <b-form-input 
              class="mt-1 mb-2 d-inline opacity-slider"
              type="range"
              min="0"
              max="100"
              v-model="textBoxBorderOpacity"/>
            <span class="opacity-label ml-1">{{ textBoxBorderOpacity }}%</span>
            <h6 class="mt-1 text-box-sub-header">FILL COLOUR</h6>
            <div class="d-flex flex-row mt-1 no-top-margin-all-descendants align-items-center">
              <span 
                v-for="(colour, index) in colourChoices" :key="`fill-${index}`"
                :class="['colour-choice rounded-circle mr-2', textBoxFillColour == colour ? 'selected-colour-choice' : '']"
                :style="`background-color: rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`"
                @click="setTextBoxFillColour(colour)">
              </span>
            </div>
            <h6 class="mt-2 text-box-sub-header">FILL OPACITY</h6>
            <b-form-input 
              class="mt-1 mb-2 d-inline opacity-slider"
              type="range"
              min="0"
              max="100"
              v-model="textBoxFillOpacity"/>
            <span class="opacity-label ml-1">{{ textBoxFillOpacity }}%</span>
          </section>
        </section>
      </div>
      <div class="mt-0 d-flex flex-row" id="drawing_toolbar_buttons">
        <b-button 
          v-if="drawingSaved && userCanAuthorInsights && isInsightsMode"
          class="mx-1 gray-button" 
          v-b-modal.confirm-drawing-delete
          v-b-tooltip.hover title="delete drawing">
          <b-icon class="black-icon" icon="trash"></b-icon>
        </b-button>
        <b-button
          v-else-if="isViewerMode || isImageMode"
          class="mx-1 gray-button" 
          v-b-modal.confirm-drawing-clear
          v-b-tooltip.hover title="clear drawing">
          <b-icon class="black-icon" icon="eraser"></b-icon>
        </b-button>
        <b-button 
          v-if="userCanAuthorInsights && (isViewerMode || isImageMode)"
          class="mx-1 no-top-margin-all-descendants gray-button" 
          @click="saveOrUpdateDrawing"
          v-b-tooltip.hover title="create insight">
          <b-img :src="'/general_icons/add insight.png'"></b-img>
        </b-button>
        <b-button 
          v-if="userCanAuthorInsights && isInsightsMode"
          class="mx-1 no-top-margin-all-descendants gray-button" 
          @click="saveOrUpdateDrawing"
          v-b-tooltip.hover title="save drawing">
          <b-img src="/general_icons/Save.png"></b-img> 
        </b-button>
        <drawing-tool-toggle
          :inDrawingTool="true"
          @toggleDrawingMode="exitDrawingMode">
        </drawing-tool-toggle>
        <b-modal id="confirm-drawing-delete" centered hide-header>
          <p>{{ deleteDrawingTextBody }}</p>
          <template #modal-footer>
            <b-button @click='$bvModal.hide("confirm-drawing-delete")' class='float-left'>Cancel</b-button>
            <b-button @click='deleteCurrentDrawing' variant='danger' class='float-right'>{{ deleteDrawingButtonText }}</b-button>
          </template>
        </b-modal>   
        <b-modal id="confirm-drawing-clear" centered hide-header>
          <p>Are you sure you want to clear the drawing?</p>
          <template #modal-footer>
            <b-button @click='$bvModal.hide("confirm-drawing-clear")' class='float-left'>Cancel</b-button>
            <b-button @click='clearCurrentDrawing' variant='danger' class='float-right'>Clear</b-button>
          </template>
        </b-modal>  
        <b-modal id="confirm-create-insight-for-drawing" centered hide-header>
          <p class="mb-1">What would you like to call the insight for this drawing?</p>
          <b-form-input v-model='newInsightTitle' placeholder='New Insight...'></b-form-input>
          <p class="mt-2 create-insight-description-text">Insight will be created as a draft.</p>
          <template #modal-footer>
            <b-button @click='cancelCreateNewInsightForDrawing' class='float-left'>Cancel</b-button>
            <b-button 
              @click='createNewInsightDrawing' 
              variant='primary' 
              class='float-right'
              :disabled="!newInsightTitle">Create</b-button>
          </template>
        </b-modal>   
      </div>
    </div>
    <div ref="fabric_canvas_container" id="fabric_canvas_container" :style="isImageMode ? 'width: calc(100% - 27rem - 10px)' : null">
      <canvas id="fabric_canvas" :style="isImageMode ? 'width: calc(100% - 27rem - 10px)' : null"></canvas>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric';
import 'fabric-history';
import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { EventBus } from '@/network/eventbus';
import { AUTHOR_INSIGHTS } from '@/constants/permissions';
import DrawingToolToggle from './DrawingToolToggle.vue';

export default {
  components: { DrawingToolToggle },
  name: 'DrawingTool',
  data() {
    return {
      drawingName: 'Drawing 1',
      drawingSaved: false,
      fabricCanvasWidth: 0,
      fabricCanvasHeight: 0,
      scaleRatio: 1,
      horizontalTranslation: 0,
      pencilDrawingMode: false,
      lineDrawingMode: false,
      shapeDrawingMode: false,
      polygonToDraw: null,
      polygonCoords: [],
      imageDrawingMode: false,
      textBoxDrawingMode: false,
      selectionMode: false,
      colourChoices: [ // colour choices in rgb
        [0, 0, 0],
        [135, 138, 143],
        [240, 240, 238],
        [249, 194, 10],
        [130, 195, 65],
        [0, 114, 188],
        [102, 255, 255],
        [205, 22, 63]
      ],
      lineTypeChoices: ['Line', 'Dotted', 'Arrow'],
      lineWeightChoices: [1, 2, 3, 4],  //specified in pixels to ensure we don't go under 1 pixel when screens are scaled or browsers are zoomed
      lineStyleChoices: [
        'regular-line-style',
        'bold-line-style',
        'white-line-style',
        'highlight-line-style'
      ],
      lineStyle: null,
      lineMenuExpanded: false,
      pencilColour: null,
      pencilLineWeight: null,
      pencilStyle: null,
      pencilStyleChoices: [
        'regular-pencil-style',
        'bold-pencil-style',
        'white-pencil-style',
        'highlight-pencil-style'
      ],
      pencilMenuOpen: false,
      pencilMenuExpanded: false,
      lineType: 'Line',
      lineColour: null,
      lineColourRGB: null,
      lineLineWeight: null,
      lineMenuOpen: false,
      pencilOrLineIsHighlighter: false,
      shapeBorderColour: null,
      shapeBorderOpacity: 100,
      shapeFillColour: null,
      shapeFillOpacity: 100,
      shapeBorderColourRGB: null,
      shapeFillColourRGB: null,
      shapeBorderThickness: null,
      selectedShape: 'square',
      shapeMenuOpen: false,
      shapeStyleChoices: [
        'bold-polygon-style',
        'rwdi-polygon-style', 
        'subtle-polygon-style', 
        'highlight-polygon-style',
        'rwdi-subtle-polygon-style',
        'outline-polygon-style',
        'cyan-highlight-polygon-style'
      ],
      shapeStyle: 'Bold',
      shapeBorderTypeChoices: ['Solid', 'Dashed'],
      shapeBorderType: 'Solid',
      shapeMenuExpanded: false,
      selectedImage: 'pine-tree',
      aerialViewTreeImage: null,
      aerialViewTree2Image: null,
      arrow1Image: null,
      arrow2Image: null,
      arrow3Image: null,
      arrow4Image: null,
      northImage: null,
      pineTreeImage: null,
      regularTreeImage: null,
      treesImage: null,
      imageMenuOpen: false,
      textItalic: false,
      textBold: false,
      textUnderline: false,
      textAlignment: 'left',
      textColour: null,
      textColourRGB: null,
      textMenuOpen: false,
      textFont: 'Inter',
      textSize: 72,
      textBoxBorderColour: null,
      textBoxBorderOpacity: 0,
      textBoxFillColour: null,
      textBoxFillOpacity: 0,
      textBoxBorderColourRGB: null,
      textBoxFillColourRGB: null,
      textBoxMenuExpanded: false,
      textBoxStyleChoices: [
        'title-text-style', 
        'label-text-style', 
        'note-text-style'
      ],
      textBoxStyle: 'Title',
      textBoxPadding: 10,
      textBoxBorderThickness: 5,
      clipboard: null,
      newInsightTitle: ''
    };
  },
  mounted() {
    let that = this;
    fabric.BorderedIText =  fabric.util.createClass(fabric.IText, {
      type: 'BorderedIText',

      toObject: function() {
        return fabric.util.object.extend(this.callSuper('toObject'), {
          borderColor: this.get('borderColor'),
          padding: this.get('padding'),
          borderThickness: this.get('borderThickness')
        });
      },

      render: function(ctx) {
        const coords = this.getCoords();

        ctx.strokeStyle = this.borderColor;
        ctx.fillStyle = this.backgroundColor;
        ctx.lineWidth = this.borderThickness;
        ctx.beginPath();
        ctx.moveTo((coords[3].x - this.padding) / that.scaleRatio, (coords[3].y + this.padding) / that.scaleRatio);
        ctx.lineTo((coords[0].x - this.padding) / that.scaleRatio, (coords[0].y - this.padding) / that.scaleRatio);
        ctx.lineTo((coords[1].x + this.padding) / that.scaleRatio, (coords[1].y - this.padding) / that.scaleRatio);
        ctx.lineTo((coords[2].x + this.padding) / that.scaleRatio, (coords[2].y + this.padding) / that.scaleRatio);
        ctx.lineTo((coords[3].x - this.padding) / that.scaleRatio, (coords[3].y + this.padding) / that.scaleRatio);
        ctx.lineTo((coords[0].x - this.padding) / that.scaleRatio, (coords[0].y - this.padding) / that.scaleRatio);
        ctx.stroke();
        ctx.fill();

        // call super render after background colour is filled, so text is visible
        this.callSuper('render', ctx);
      },
    });

    fabric.BorderedIText.fromObject = function(object, callback) {
      return fabric.Object._fromObject('BorderedIText', object, callback, 'text');
    };

    this.fabricCanvas = new fabric.Canvas('fabric_canvas');
    this.fabricCanvasHeight = this.$refs.fabric_canvas_container.clientHeight;
    this.fabricCanvasWidth = this.$refs.fabric_canvas_container.clientWidth;
    if(this.isInsightsMode) this.loadCurrentInsightDrawing();
    setTimeout(() => {
      this.resizeFabricCanvas();
      this.scaleFabricCanvas();
    }, 100);
    if (this.isImageMode || this.isViewerMode) this.resetDrawingAndTurnDrawingModeOff();
    window.onresize = () => {
      this.scaleFabricCanvas();
      this.resizeFabricCanvas();
    };
    if(this.$parent.$refs.current_image) {
      this.$parent.$refs.current_image.onload = () => {
        this.scaleFabricCanvas();
        this.resizeFabricCanvas();
      };
    }

    // initialize values
    this.setTextBoxStyle('title-text-style');
    this.setShapeStyle('bold-polygon-style');
    this.setLineStyle('regular-line-style');
    this.setPencilStyle('regular-pencil-style');
    
    document.onkeydown = (e) => {
      if(this.drawingMode) { 
        if(e.code == 'Delete') {
          this.deleteSelectedObject();
        } else if (e.key == 'z' && e.ctrlKey && !e.shiftKey){
          this.fabricCanvas.undo();
        } else if (e.key == 'Z' && e.ctrlKey && e.shiftKey) {
          this.fabricCanvas.redo();
        } else if(e.ctrlKey && e.key == 'c'){
          this.copySelectedObject();
        } else if(e.ctrlKey && e.key == 'v') {
          this.pasteObjectFromClipboard();
        }
      }
    };

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/aerial-view-tree.svg`, (img) => {
      this.aerialViewTreeImage = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/aerial-view-tree-2.svg`, (img) => {
      this.aerialViewTree2Image = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/arrow-1.svg`, (img) => {
      this.arrow1Image = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/arrow-2.svg`, (img) => {
      this.arrow2Image = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/arrow-3.svg`, (img) => {
      this.arrow3Image = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/arrow-4.svg`, (img) => {
      this.arrow4Image = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/north.svg`, (img) => {
      this.northImage = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/pine-tree.svg`, (img) => {
      this.pineTreeImage = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/regular-tree.svg`, (img) => {
      this.regularTreeImage = img;
    });

    fabric.Image.fromURL(`${window.location.origin}/drawing_tool_images/trees.svg`, (img) => {
      this.treesImage = img;
    });

    let rectToDraw,
      circleToDraw,
      triangleToDraw,
      lineToDraw, 
      arrowToDraw, 
      lineWithArrowToDraw, 
      angleOfLine, 
      initPos, 
      polygonPath, 
      polygonFill,
      lastVertex = false,
      imageToDraw,
      textBoxToDraw;
    this.fabricCanvas.on('mouse:down', (e) => {
      initPos = e.pointer;
      if(this.shapeDrawingMode && this.selectedShape == 'square') {
        this.fabricCanvas.offHistory();
        rectToDraw = new fabric.Rect({
          left: e.pointer.x / this.scaleRatio,
          top: e.pointer.y / this.scaleRatio,
          rx: 0,
          ry: 0,
          fill: this.shapeFillColourRGB,
          stroke: this.shapeBorderColourRGB,
          selectable: false,
          strokeWidth: this.shapeBorderThickness,
          strokeDashArray: this.shapeBorderType == 'Dashed' ? [5, 5] : []
        });
        this.fabricCanvas.add(rectToDraw);
      } else if (this.shapeDrawingMode && this.selectedShape == 'circle') { 
        this.fabricCanvas.offHistory();
        circleToDraw = new fabric.Ellipse({
          left: e.pointer.x / this.scaleRatio,
          top: e.pointer.y / this.scaleRatio,
          rx: 0,
          ry: 0,
          fill: this.shapeFillColourRGB,
          stroke: this.shapeBorderColourRGB,
          selectable: false,
          strokeWidth: this.shapeBorderThickness,
          strokeDashArray: this.shapeBorderType == 'Dashed' ? [5, 5] : []
        });
        this.fabricCanvas.add(circleToDraw);
      } else if(this.shapeDrawingMode && this.selectedShape == 'triangle') {
        this.fabricCanvas.offHistory();
        triangleToDraw = new fabric.Triangle({
          left: e.pointer.x / this.scaleRatio,
          top: e.pointer.y / this.scaleRatio,
          width: 0,
          height: 0,
          fill: this.shapeFillColourRGB,
          stroke: this.shapeBorderColourRGB,
          selectable: false,
          strokeWidth: this.shapeBorderThickness,
          strokeDashArray: this.shapeBorderType == 'Dashed' ? [5, 5] : []
        });
        this.fabricCanvas.add(triangleToDraw);
      } else if (this.lineDrawingMode) {
        this.fabricCanvas.offHistory();
        lineToDraw = new fabric.Line([
          initPos.x / this.scaleRatio, 
          initPos.y / this.scaleRatio, 
          initPos.x / this.scaleRatio, 
          initPos.y / this.scaleRatio
        ], {
          stroke: this.lineColourRGB,
          strokeWidth: this.lineLineWeight,
          strokeDashArray: this.lineType == 'Dotted' ? [5, 5] : [],
          selectable: false
        });
        if(this.lineType === 'Line' || this.lineType === 'Dotted') {
          this.fabricCanvas.add(lineToDraw);
        } else if (this.lineType === 'Arrow') {
          arrowToDraw = new fabric.Triangle({
            width: 20, 
            height: 20, 
            fill: this.lineColourRGB,
            left: initPos.x / this.scaleRatio,
            top: initPos.y / this.scaleRatio,
            selectable: false
          });
          lineWithArrowToDraw = new fabric.Group([lineToDraw, arrowToDraw], { selectable: false });
          this.fabricCanvas.add(lineWithArrowToDraw);
        }
      } else if (this.shapeDrawingMode && this.selectedShape == 'polygon') {
        this.fabricCanvas.offHistory();
        this.polygonCoords.push([e.pointer.x / this.scaleRatio, e.pointer.y / this.scaleRatio]);
        polygonPath = 'M';
        polygonFill = 'rgba(0,0,0,0)';
        this.polygonCoords.forEach(coords => {
          if (polygonPath == 'M') {
            polygonPath += ` ${coords[0]} ${coords[1]}`;
          } else {
            polygonPath += ` L ${coords[0]} ${coords[1]}`;
          }
        });
        if(this.polygonCoords.length > 1) {
          if(Math.abs(this.polygonCoords[0][0] - this.polygonCoords[this.polygonCoords.length - 1][0]) < 3 &&
            Math.abs(this.polygonCoords[0][1] - this.polygonCoords[this.polygonCoords.length - 1][1]) < 3) {
            polygonPath += ' z';
            polygonFill = this.shapeFillColourRGB;
            lastVertex = true;
          }
        }
        this.fabricCanvas.remove(this.polygonToDraw);
        this.polygonToDraw = new fabric.Path(polygonPath, {
          fill: polygonFill, 
          stroke: this.shapeBorderColourRGB,
          strokeWidth: this.shapeBorderThickness,
          strokeDashArray: this.shapeBorderType == 'Dashed' ? [5, 5] : []
        });
        this.fabricCanvas.add(this.polygonToDraw);
        if(lastVertex) {
          lastVertex = false;
          this.polygonCoords = [];
          this.polygonToDraw = null;
          this.turnSelectionModeOn();
          this.fabricCanvas.onHistory();
        }
      } else if(this.imageDrawingMode) {
        if(this.selectedImage === 'aerial-view-tree') {
          imageToDraw = _.cloneDeep(this.aerialViewTreeImage);
        } else if(this.selectedImage === 'aerial-view-tree-2') {
          imageToDraw = _.cloneDeep(this.aerialViewTree2Image);
        } else if(this.selectedImage === 'arrow-1') {
          imageToDraw = _.cloneDeep(this.arrow1Image);
        } else if(this.selectedImage === 'arrow-2') {
          imageToDraw = _.cloneDeep(this.arrow2Image);
        } else if(this.selectedImage === 'arrow-3') {
          imageToDraw = _.cloneDeep(this.arrow3Image);
        } else if(this.selectedImage === 'arrow-4') {
          imageToDraw = _.cloneDeep(this.arrow4Image);
        } else if(this.selectedImage === 'north') {
          imageToDraw = _.cloneDeep(this.northImage);
        } else if(this.selectedImage === 'pine-tree') {
          imageToDraw = _.cloneDeep(this.pineTreeImage);
        } else if(this.selectedImage === 'regular-tree') {
          imageToDraw = _.cloneDeep(this.regularTreeImage);
        } else if(this.selectedImage === 'trees') {
          imageToDraw = _.cloneDeep(this.treesImage);
        } 
        imageToDraw.set({
          left: (e.pointer.x - imageToDraw.width/2) / this.scaleRatio,
          top: (e.pointer.y - imageToDraw.height/2) / this.scaleRatio,
          selectable: false
        });
        this.fabricCanvas.add(imageToDraw);
        this.turnSelectionModeOn();
      } else if(this.textBoxDrawingMode) {
        textBoxToDraw = new fabric.BorderedIText('Write here...', {
          width: 0,
          height: 0,
          top: initPos.y / this.scaleRatio,
          left: initPos.x / this.scaleRatio,
          borderColor: this.textBoxBorderColourRGB,
          padding: this.textBoxPadding,
          borderThickness: this.textBoxBorderThickness,
          fontFamily: this.textFont,
          fontSize: this.textSize,
          fontWeight: this.textBold ? 'bold' : 'normal',
          fontStyle: this.textItalic ? 'italic' : 'normal',
          underline: this.textUnderline,
          textAlign: this.textAlignment,
          fill: this.textColourRGB,
          backgroundColor: this.textBoxFillColourRGB,
          lockRotation: true
        });
        this.fabricCanvas.add(textBoxToDraw);
      }
    });

    this.fabricCanvas.on('mouse:dblclick', () => {
      if(this.shapeDrawingMode && this.selectedShape == 'polygon') {
        this.polygonCoords = [];
        this.polygonToDraw = null;
        this.turnSelectionModeOn();
        this.fabricCanvas.onHistory();
      }
    });

    this.fabricCanvas.on('mouse:move', (e) => {
      if(rectToDraw && this.shapeDrawingMode) {
        rectToDraw.set({
          width: Math.abs(initPos.x - e.pointer.x) / this.scaleRatio,
          height: Math.abs(initPos.y - e.pointer.y) / this.scaleRatio,
          left: Math.min(initPos.x, e.pointer.x) / this.scaleRatio,
          top: Math.min(initPos.y, e.pointer.y) / this.scaleRatio
        });
        this.fabricCanvas.remove(rectToDraw);
        this.fabricCanvas.add(rectToDraw);
      } else if (circleToDraw && this.shapeDrawingMode) {
        circleToDraw.set({
          rx: Math.abs(initPos.x - e.pointer.x) / (2*this.scaleRatio),
          ry: Math.abs(initPos.y - e.pointer.y) / (2*this.scaleRatio),
          left: Math.min(initPos.x, e.pointer.x) / this.scaleRatio,
          top: Math.min(initPos.y, e.pointer.y) / this.scaleRatio
        });
        this.fabricCanvas.remove(circleToDraw);
        this.fabricCanvas.add(circleToDraw); 
      } else if(triangleToDraw && this.shapeDrawingMode) {
        triangleToDraw.set({
          width: Math.abs(initPos.x - e.pointer.x) / this.scaleRatio,
          height: Math.abs(initPos.y - e.pointer.y) / this.scaleRatio,
          left: Math.min(initPos.x, e.pointer.x) / this.scaleRatio,
          top: Math.min(initPos.y, e.pointer.y) / this.scaleRatio
        });
        this.fabricCanvas.remove(triangleToDraw);
        this.fabricCanvas.add(triangleToDraw);
      } else if (lineToDraw && this.lineDrawingMode) {
        lineToDraw.set({
          x2: e.pointer.x / this.scaleRatio,
          y2: e.pointer.y / this.scaleRatio
        });
        if(this.lineType === 'Line' || this.lineType === 'Dotted') {
          this.fabricCanvas.remove(lineToDraw);
          this.fabricCanvas.add(lineToDraw);
        } else if (this.lineType === 'Arrow') {
          angleOfLine = Math.atan2((e.pointer.y - initPos.y) / this.scaleRatio, (e.pointer.x - initPos.x) / this.scaleRatio);
          arrowToDraw.set({
            left: (e.pointer.x - Math.cos(angleOfLine + Math.PI/2) * 10 + Math.cos(angleOfLine) * 10) / this.scaleRatio,
            top: (e.pointer.y - Math.sin(angleOfLine + Math.PI/2) * 10 + Math.sin(angleOfLine) * 10) / this.scaleRatio,
            angle: angleOfLine * (180/Math.PI) + 90 
          });
          this.fabricCanvas.remove(lineWithArrowToDraw);
          lineWithArrowToDraw = new fabric.Group([lineToDraw, arrowToDraw], { selectable: false });
          this.fabricCanvas.add(lineWithArrowToDraw); 
        }
      }
    });

    this.fabricCanvas.on('mouse:up', (e) => {
      if(rectToDraw && this.shapeDrawingMode) {
        rectToDraw.set({
          width: Math.abs(initPos.x - e.pointer.x) / this.scaleRatio,
          height: Math.abs(initPos.y - e.pointer.y) / this.scaleRatio,
          left: Math.min(initPos.x, e.pointer.x) / this.scaleRatio,
          top: Math.min(initPos.y, e.pointer.y) / this.scaleRatio
        });
        this.fabricCanvas.remove(rectToDraw);
        this.fabricCanvas.add(rectToDraw);
        this.fabricCanvas.onHistory();
      } else if (circleToDraw && this.shapeDrawingMode) {
        circleToDraw.set({
          rx: Math.abs(initPos.x - e.pointer.x) / (2*this.scaleRatio),
          ry: Math.abs(initPos.y - e.pointer.y) / (2*this.scaleRatio),
          left: Math.min(initPos.x, e.pointer.x) / this.scaleRatio,
          top: Math.min(initPos.y, e.pointer.y) / this.scaleRatio
        });
        this.fabricCanvas.remove(circleToDraw);
        this.fabricCanvas.add(circleToDraw); 
        this.fabricCanvas.onHistory();
      } else if(triangleToDraw && this.shapeDrawingMode) {
        triangleToDraw.set({
          width: Math.abs(initPos.x - e.pointer.x) / this.scaleRatio,
          height: Math.abs(initPos.y - e.pointer.y) / this.scaleRatio,
          left: Math.min(initPos.x, e.pointer.x) / this.scaleRatio,
          top: Math.min(initPos.y, e.pointer.y) / this.scaleRatio
        });
        this.fabricCanvas.remove(triangleToDraw);
        this.fabricCanvas.add(triangleToDraw);
        this.fabricCanvas.onHistory();
      } else if (lineToDraw && this.lineDrawingMode) {
        lineToDraw.set({
          x2: e.pointer.x / this.scaleRatio,
          y2: e.pointer.y / this.scaleRatio
        });
        if(this.lineType === 'Line' || this.lineType === 'Dotted') {
          this.fabricCanvas.remove(lineToDraw);
          this.fabricCanvas.add(lineToDraw);
          this.fabricCanvas.onHistory();
        } else if (this.lineType === 'Arrow') {
          angleOfLine = Math.atan2((e.pointer.y - initPos.y) / this.scaleRatio, (e.pointer.x - initPos.x) / this.scaleRatio);
          arrowToDraw.set({
            left: (e.pointer.x - Math.cos(angleOfLine + Math.PI/2) * 10 + Math.cos(angleOfLine) * 10) / this.scaleRatio,
            top: (e.pointer.y - Math.sin(angleOfLine + Math.PI/2) * 10 + Math.sin(angleOfLine) * 10) / this.scaleRatio,
            angle: angleOfLine * (180/Math.PI) + 90 
          });
          this.fabricCanvas.remove(lineWithArrowToDraw);
          lineWithArrowToDraw = new fabric.Group([lineToDraw, arrowToDraw], { selectable: false });
          this.fabricCanvas.add(lineWithArrowToDraw); 
          this.fabricCanvas.onHistory();
        }
      }

      if(this.shapeDrawingMode && this.selectedShape != 'polygon') { 
        rectToDraw = null;
        circleToDraw = null;
        triangleToDraw = null;
        this.turnSelectionModeOn();
      } else if (this.lineDrawingMode) {
        lineToDraw = null;
        arrowToDraw = null;
        lineWithArrowToDraw = null;
        this.turnSelectionModeOn();
      } else if (this.textBoxDrawingMode) {
        textBoxToDraw = null;
        this.turnSelectionModeOn();
      }
    });

    if(this.userCanAuthorInsights) {
      this.turnSelectionModeOn();
    } else {
      this.setAllObjectsSelectable(false);
    }
  },
  computed: {
    deleteDrawingTextBody() {
      if(this.isInsightsMode) {
        return 'Are you sure you want to delete the drawing?';
      } else {
        return 'Are you sure you want to exit drawing mode? Unsaved work will be lost.';
      }
    },
    deleteDrawingButtonText() {
      return this.isInsightsMode ? 'Delete' : 'Exit';
    },
    isInsightsMode() {
      return this.viewerMode === 'Insights';
    },
    isViewerMode() {
      return this.viewerMode === 'Viewer';
    },
    isImageMode() {
      return this.viewerMode === 'Images';
    },
    userCanAuthorInsights() {
      return (this.$store.getters['allowedPermissions'] || {})[AUTHOR_INSIGHTS];
    },
    isSceneView() {
      return this.currentInsight?.insight_type === 'Scene';
    },
    isImageView() {
      return this.currentInsight?.insight_type === 'Image';
    },
    isOnScene() {
      return (this.isInsightsMode && this.isSceneView) || this.isViewerMode; 
    },
    isOnImage() {
      return (this.isInsightsMode && this.isImageView) || this.isImageMode;
    },
    ...mapGetters('project', ['drawingMode', 'currentInsight', 'viewerMode', 'currentInsightIndex', 'layersPanelOpen', 'sceneViewCompositionMode'])
  },
  methods: {
    createInsightLink() {
      const locationOrigin = String(window.location.origin);
      const link = `${locationOrigin}/projects/${this.$route.params.id}/viewer/${this.$route.params.study}/configuration/${this.$route.params.configuration}/insights?page=${this.currentInsightIndex}`;
      return link;
    },
    deleteCurrentDrawing() {
      if(this.isInsightsMode) {
        let drawing_id;
        if(this.isSceneView) {
          drawing_id = this.currentInsight.sceneview_set[0].drawings[0].id;
        } else if(this.isImageView) {
          drawing_id = this.currentInsight.imageview_set[0].drawings[0].id;
        }

        this.deleteDrawing(drawing_id);
        this.fabricCanvas.clear();
        this.setDrawingMode(false);

        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'Drawing deleted'
        });
      } else {
        this.fabricCanvas.clear();
        this.setDrawingMode(false);
      }

      this.$bvModal.hide('confirm-drawing-delete');
    },
    clearCurrentDrawing() {
      this.fabricCanvas.clear();
      this.$bvModal.hide('confirm-drawing-clear');
    },
    exitDrawingMode() {
      if(this.isInsightsMode) {
        this.setDrawingMode(false);
      } else {
        this.$bvModal.show('confirm-drawing-delete');
      }
    },
    saveOrUpdateDrawing() {
      let fabricCanvasData = JSON.stringify(this.fabricCanvas);

      if(this.isInsightsMode) {
        this.scaleFabricCanvas(true);
        if(!this.drawingSaved) {
          this.saveDrawing();
        } else {
          let updateDrawingObj = {
            name: 'Insight Drawing',
            drawing_json: fabricCanvasData,
            horizontal_translation_factor: this.horizontalTranslation
          };

          if(this.isSceneView) {
            updateDrawingObj['drawing_id'] = this.currentInsight.sceneview_set[0].drawings[0].id;
          } else if(this.isImageView) {
            updateDrawingObj['drawing_id'] = this.currentInsight.imageview_set[0].drawings[0].id;
          }

          this.updateDrawing(updateDrawingObj);
        }

        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'Drawing saved'
        });
      } else if (this.isViewerMode || this.isImageMode){
        this.$bvModal.show('confirm-create-insight-for-drawing');
      }
    },
    createNewInsightDrawing() {
      this.$store.dispatch('project/setNewInsightTitle', this.newInsightTitle);
      this.$emit('createNewInsight', () => {
        this.newInsightTitle = '';
        this.$store.dispatch('project/setNewInsightTitle', '');
        if(this.isViewerMode) {
          this.$emit('saveSceneForNewInsight', () => {
            this.saveDrawing();

            this.$bvModal.hide('confirm-create-insight-for-drawing');
            EventBus.$emit('TOAST', {
              variant: 'success',
              content: 'Drawing saved as insight',
              href: this.createInsightLink()  
            });
          });
        } else if (this.isImageMode) {
          this.$emit('saveImageViewForNewInsight', () => {
            this.saveDrawing();

            this.$bvModal.hide('confirm-create-insight-for-drawing');
            EventBus.$emit('TOAST', {
              variant: 'success',
              content: 'Drawing saved as insight',
              href: this.createInsightLink()  
            });
          });
        }
      });
    },
    cancelCreateNewInsightForDrawing() {
      this.$bvModal.hide('confirm-create-insight-for-drawing');
      this.newInsightTitle = '';
    },
    saveDrawing() {
      let fabricCanvasData = JSON.stringify(this.fabricCanvas);
      let saveDrawingObj = {
        name: 'Insight Drawing',
        drawing_json: fabricCanvasData,
        width: this.fabricCanvasWidth,
        height: this.fabricCanvasHeight,
        horizontal_translation_factor: this.horizontalTranslation
      };

      if(this.isSceneView) {
        saveDrawingObj['scene_view_id'] = this.currentInsight.sceneview_set[0].id;
      } else if(this.isImageView) {
        saveDrawingObj['image_view_id'] = this.currentInsight.imageview_set[0].id;
      }

      this.saveDrawingStore(saveDrawingObj);
      this.drawingSaved = true;
    },
    loadFabricCanvas(data, width, height, horizontalTranslation) {
      this.fabricCanvas.loadFromJSON(data);
      this.setAllObjectsSelectable(this.selectionMode);
      this.fabricCanvasWidth = width;
      this.fabricCanvasHeight = height;
      this.horizontalTranslation = horizontalTranslation;
      this.resizeFabricCanvas();
      this.scaleFabricCanvas();
    },
    deleteSelectedObject() {
      let activeObject = this.fabricCanvas.getActiveObject();
      if(activeObject.type == 'activeSelection') {
        activeObject.getObjects().forEach(obj => this.fabricCanvas.remove(obj));
        this.fabricCanvas.discardActiveObject();
      } else if (activeObject.type != 'BorderedIText' || !activeObject.isEditing) {
        this.fabricCanvas.remove(activeObject);
      }
      this.polygonToDraw = null;
      this.polygonCoords = [];
    },
    copySelectedObject() {
      let activeObject = this.fabricCanvas.getActiveObject();
      if(activeObject) {     
        if(!activeObject.hiddenTextarea) {
          activeObject.clone((object) => {
            this.clipboard = object;
          });
        }
      } else {
        this.clipboard = null;
      }
    },
    pasteObjectFromClipboard() {
      if(this.clipboard) {
        let activeObject = this.fabricCanvas.getActiveObject();
        if(!activeObject?.hiddenTextarea) {
          this.clipboard.clone((object) => {
            this.fabricCanvas.discardActiveObject();
            object.set({
              left: object.left + 10,
              top: object.top + 10
            });
            this.fabricCanvas.offHistory();
            if(object.type == 'activeSelection') {
              object.canvas = this.fabricCanvas;
              object.forEachObject((obj) => {
                this.fabricCanvas.add(obj);
              });
            } else {
              this.fabricCanvas.add(object);
            }
            this.clipboard.top += 10;
            this.clipboard.left += 10;
            this.fabricCanvas.setActiveObject(object);
            this.fabricCanvas.renderAll();
            this.fabricCanvas.onHistory();
          });
        }
      }
    },
    turnPencilOn() {
      let menuOpen = this.pencilMenuOpen;
      this.turnOffAllDrawingModes();
      this.pencilDrawingMode = true;
      this.fabricCanvas.isDrawingMode = true;
      this.pencilMenuOpen = !menuOpen;
      this.fabricCanvas.hoverCursor = 'move';
    },
    closePencilMenu() {
      this.pencilMenuOpen = false;
    },
    setPencilColour(colour) {
      this.pencilColour = colour;
      this.pencilOrLineIsHighlighter = false;
    },
    setPencilLineWeight(lineWeight) {
      this.pencilLineWeight = lineWeight;
      this.pencilOrLineIsHighlighter = false;
    },
    setPencilStyle(style) {
      this.pencilStyle = style;
      if(style == 'regular-pencil-style') {
        this.pencilColour = this.colourChoices[0];
        this.pencilLineWeight = this.lineWeightChoices[0];
        this.pencilOrLineIsHighlighter = false;
      } else if(style == 'bold-pencil-style') {
        this.pencilColour = this.colourChoices[7];
        this.pencilLineWeight = this.lineWeightChoices[1];
        this.pencilOrLineIsHighlighter = false;
      } else if (style == 'white-pencil-style') {
        this.pencilColour = this.colourChoices[2];
        this.pencilLineWeight = this.lineWeightChoices[3];
        this.pencilOrLineIsHighlighter = false;
      } else if (style == 'highlight-pencil-style') {
        this.pencilColour = this.colourChoices[3];
        this.pencilLineWeight = this.lineWeightChoices[3];
        this.pencilOrLineIsHighlighter = true;
      }
    },
    togglePencilMenuExpanded() {
      this.pencilMenuExpanded = !this.pencilMenuExpanded;
    },
    turnLineDrawingModeOn() {
      let menuOpen = this.lineMenuOpen;
      this.turnOffAllDrawingModes();
      this.lineMenuOpen = !menuOpen;
      this.lineDrawingMode = true;
      this.fabricCanvas.hoverCursor = 'auto';
    },
    closeLineMenu() {
      this.lineMenuOpen = false;
    },
    setLineType(type) {
      this.lineType = type;
    },
    setLineColour(colour) {
      this.lineColour = colour;
      this.pencilOrLineIsHighlighter = false;
    },
    setLineLineWeight(lineWeight) {
      this.lineLineWeight = lineWeight;
      this.pencilOrLineIsHighlighter = false;
    },
    toggleLineMenuExpanded() {
      this.lineMenuExpanded = !this.lineMenuExpanded;
    },
    setLineStyle(style) {
      this.lineStyle = style;
      if(style == 'regular-line-style') {
        this.lineColour = this.colourChoices[0];
        this.lineLineWeight = this.lineWeightChoices[0];
        this.pencilOrLineIsHighlighter = false;
      } else if (style == 'bold-line-style') {
        this.lineColour = this.colourChoices[7];
        this.lineLineWeight = this.lineWeightChoices[1];
        this.pencilOrLineIsHighlighter = false;
      } else if (style == 'white-line-style') {
        this.lineColour = this.colourChoices[2];
        this.lineLineWeight = this.lineWeightChoices[3];
        this.pencilOrLineIsHighlighter = false;
      } else if (style == 'highlight-line-style') {
        this.lineColour = this.colourChoices[3];
        this.lineLineWeight = this.lineWeightChoices[3];
        this.pencilOrLineIsHighlighter = true;
      }
    },
    turnShapeDrawingModeOn() {
      let menuOpen = this.shapeMenuOpen;
      this.turnOffAllDrawingModes();
      this.shapeMenuOpen = !menuOpen;
      this.shapeDrawingMode = true;
      this.polygonToDraw = null;
      this.polygonCoords = [];
      this.fabricCanvas.hoverCursor = 'auto';
    },
    setSelectedShape(shape) {
      this.selectedShape = shape;
    },
    closeShapeMenu() {
      this.shapeMenuOpen = false;
    },
    setShapeBorderColour(colour) {
      this.shapeStyle = 'Custom';
      this.shapeBorderColour = colour;
    },
    setShapeBorderType(type) {
      this.shapeStyle = 'Custom';
      this.shapeBorderType = type;
    },
    setShapeFillColour(colour) {
      this.shapeStyle = 'Custom';
      this.shapeFillColour = colour;
    },
    setShapeStyle(style) {
      this.shapeStyle = style;
      if(style == 'bold-polygon-style') {
        this.shapeBorderType = 'Solid';
        this.shapeBorderColour = this.colourChoices[0];
        this.shapeBorderOpacity = 100;
        this.shapeFillColour = this.colourChoices[7];
        this.shapeFillOpacity = 100;
        this.shapeBorderThickness = 1;
      } else if (style == 'subtle-polygon-style') {
        this.shapeBorderType = 'Dashed';
        this.shapeBorderColour = this.colourChoices[4];
        this.shapeBorderOpacity = 100;
        this.shapeFillColour = this.colourChoices[4];
        this.shapeFillOpacity = 30;
        this.shapeBorderThickness = 3;
      } else if (style == 'highlight-polygon-style') {
        this.shapeBorderType = 'Dashed';
        this.shapeBorderColour = this.colourChoices[3];
        this.shapeBorderOpacity = 100;
        this.shapeFillColour = this.colourChoices[3];
        this.shapeFillOpacity = 30;
        this.shapeBorderThickness = 3;
      } else if (style == 'rwdi-polygon-style') {
        this.shapeBorderType = 'Solid';
        this.shapeBorderColour = this.colourChoices[0];
        this.shapeBorderOpacity = 100;
        this.shapeFillColour = this.colourChoices[5];
        this.shapeFillOpacity = 100;
        this.shapeBorderThickness = 1;
      } else if (style == 'cyan-highlight-polygon-style') {
        this.shapeBorderType = 'Solid';
        this.shapeBorderColour = this.colourChoices[6];
        this.shapeBorderOpacity = 100;
        this.shapeFillOpacity = 0;
        this.shapeBorderThickness = 6;
      } else if (style == 'outline-polygon-style') {
        this.shapeBorderType = 'Solid';
        this.shapeBorderColour = this.colourChoices[0];
        this.shapeBorderOpacity = 100;
        this.shapeFillOpacity = 0;
        this.shapeBorderThickness = 2;
      } else if (style == 'rwdi-subtle-polygon-style') {
        this.shapeBorderType = 'Solid';
        this.shapeBorderColour = this.colourChoices[5];
        this.shapeBorderOpacity = 100;
        this.shapeFillColour = this.colourChoices[5];
        this.shapeFillOpacity = 30;
        this.shapeBorderThickness = 3;
      }
    },
    toggleShapeMenuExpanded() {
      this.shapeMenuExpanded = !this.shapeMenuExpanded;
    },
    turnImageDrawingModeOn() {
      let menuOpen = this.imageMenuOpen;
      this.turnOffAllDrawingModes();
      this.imageMenuOpen = !menuOpen;
      this.imageDrawingMode = true;
      this.fabricCanvas.hoverCursor = 'auto';
    },
    setSelectedImage(image) {
      this.selectedImage = image;
    },
    closeImageMenu() {
      this.imageMenuOpen = false;
    },
    turnTextBoxDrawingModeOn() {
      let menuOpen = this.textMenuOpen;
      this.turnOffAllDrawingModes();
      this.textMenuOpen = !menuOpen;
      this.textBoxDrawingMode = true;
      this.fabricCanvas.hoverCursor = 'auto';
    },
    closeTextMenu() {
      this.textMenuOpen = false;
    },
    setTextColour(colour) {
      this.textBoxStyle = 'Custom';
      this.textColour = colour;
    },
    toggleTextItalic() {
      this.textBoxStyle = 'Custom';
      this.textItalic = !this.textItalic;
    },
    toggleTextBold() {
      this.textBoxStyle = 'Custom';
      this.textBold = !this.textBold;
    },
    toggleTextUnderline() {
      this.textBoxStyle = 'Custom';
      this.textUnderline = !this.textUnderline;
    },
    setTextAlignment(alignment) {
      this.textBoxStyle = 'Custom';
      this.textAlignment = alignment;
    },
    setTextFont(font) {
      this.textBoxStyle = 'Custom';
      this.textFont = font;
    },
    setTextSize(size) {
      this.textBoxStyle = 'Custom';
      this.textSize = size;
    },
    setTextBoxBorderColour(colour) {
      this.textBoxStyle = 'Custom';
      this.textBoxBorderColour = colour;
    },
    setTextBoxFillColour(colour) {
      this.textBoxStyle = 'Custom';
      this.textBoxFillColour = colour;
    },
    toggleTextBoxMenuExpanded() {
      this.textBoxMenuExpanded = !this.textBoxMenuExpanded;
    },
    setTextBoxStyle(style) {
      this.textBoxStyle = style;
      if(style == 'title-text-style') {
        this.textSize = '24';
        this.textFont = 'Inter';
        this.textBoxBorderColour = this.colourChoices[5];
        this.textBoxBorderOpacity = 100;
        this.textBoxFillColour = this.colourChoices[2];
        this.textBoxFillOpacity = 100;
        this.textColour = this.colourChoices[5];
        this.textItalic = false;
        this.textBold = true;
        this.textUnderline = false;
        this.textAlignment = 'center';
        this.textBoxPadding = 10;
        this.textBoxBorderThickness = 5;
      } else if (style == 'label-text-style') {
        this.textSize = '16';
        this.textFont = 'Inter';
        this.textBoxBorderColour = this.colourChoices[5];
        this.textBoxBorderOpacity = 100;
        this.textBoxFillColour = this.colourChoices[2];
        this.textBoxFillOpacity = 100;
        this.textColour = this.colourChoices[5];
        this.textItalic = false;
        this.textBold = false;
        this.textUnderline = false;
        this.textAlignment = 'center';
        this.textBoxPadding = 5;
        this.textBoxBorderThickness = 3;
      } else if (style == 'note-text-style') {
        this.textSize = '12';
        this.textFont = 'Inter';
        this.textBoxBorderColour = this.colourChoices[0];
        this.textBoxBorderOpacity = 100;
        this.textBoxFillColour = this.colourChoices[2];
        this.textBoxFillOpacity = 100;
        this.textColour = this.colourChoices[0];
        this.textItalic = false;
        this.textBold = false;
        this.textUnderline = false;
        this.textAlignment = 'left';
        this.textBoxPadding = 0;
        this.textBoxBorderThickness = 1;
      }
    },
    turnSelectionModeOn() {
      this.turnOffAllDrawingModes();
      this.setAllObjectsSelectable(true);
      this.selectionMode = true;
      this.fabricCanvas.hoverCursor = 'move';
    },
    turnOffAllDrawingModes() {
      this.setAllObjectsSelectable(false);
      this.selectionMode = false;
      this.fabricCanvas.isDrawingMode = false;
      this.pencilDrawingMode = false;
      this.pencilMenuOpen = false;
      this.lineDrawingMode = false;
      this.lineMenuOpen = false;
      this.shapeDrawingMode = false;
      this.shapeMenuOpen = false;
      this.imageDrawingMode = false;
      this.imageMenuOpen = false;
      this.textBoxDrawingMode = false;
      this.textMenuOpen = false;
    },
    resizeFabricCanvas() {
      let containerWidth = this.$refs.fabric_canvas_container?.clientWidth;
      let containerHeight = this.$refs.fabric_canvas_container?.clientHeight;
      if(containerWidth && containerHeight) {
        this.fabricCanvas.setWidth(this.$refs.fabric_canvas_container?.clientWidth);
        this.fabricCanvas.setHeight(this.$refs.fabric_canvas_container?.clientHeight);
        this.fabricCanvas.renderAll();
      }
    },
    scaleFabricCanvas(beforeSave=false) {
      let mx = 0, my = 0, setScale = false;
      if(this.isOnScene && this.$refs.fabric_canvas_container?.clientHeight > 0) {
        this.scaleRatio = this.$refs.fabric_canvas_container.clientHeight / this.fabricCanvasHeight;
        let targetWidth = this.fabricCanvasWidth * this.scaleRatio;
        let widthDifference = this.$refs.fabric_canvas_container.clientWidth - targetWidth;
        mx = widthDifference/2;
        setScale = true;
      } else if (this.isOnImage && this.$refs.fabric_canvas_container?.clientWidth > 0 && this.$parent.$refs.current_image?.naturalHeight > 0) {
        // ci = current image; ii = initial image; ic = initial canvas; cc = current canvas
        let ciWidth = this.$parent.$refs.current_image.width;
        let ciMaxHeight = this.$parent.$refs.current_image.naturalHeight;
        let ciMaxWidth = this.$parent.$refs.current_image.naturalHeight;
        let icWidth = this.fabricCanvasWidth;
        let icHeight = this.fabricCanvasHeight;
        let iiWidth;
        let ciMWHRatio = ciMaxWidth / ciMaxHeight; // current image max width max height ratio
        let icWHRatio = this.fabricCanvasWidth / this.fabricCanvasHeight; // initial canvas width height ratio
        let ccWidth = this.$refs.fabric_canvas_container.clientWidth;

        // this scaling/resizing should only occur if the image has fully loaded. If it hasn't these values are 0 and it breaks the drawing tool.
        if(ciMaxHeight > 0 && ciMaxWidth > 0) {
          // calculate initial image width (height is not needed, as the entire calculation can be done based on width)
          if(icWidth >= ciMaxWidth && icHeight >= ciMaxHeight) {
            iiWidth = ciMaxWidth;
          } else if(icWHRatio == ciMWHRatio && icWidth < ciMaxWidth) {
            iiWidth = icWidth;
          } else if(icWHRatio < ciMWHRatio && icWidth < ciMaxWidth) {
            iiWidth = icWidth;
          } else if(icWHRatio > ciMWHRatio && icHeight < ciMaxHeight) {
            iiWidth = ciMaxWidth * (icHeight / ciMaxHeight);
          }

          this.scaleRatio = ciWidth / iiWidth;
          mx = ((ccWidth - ciWidth) - (icWidth - iiWidth)*this.scaleRatio) / 2;
          my = -5; // to account for the border
          setScale = true;
        }
      }
      
      if(setScale) {
        this.fabricCanvas.setZoom(this.scaleRatio);
        this.fabricCanvas.getObjects().forEach(object => {
          if(!object.initialLeft) {
            // sometimes for scene views, horizontalTranslation === 0, so need to check if scene view
            if(this.horizontalTranslation || this.isOnScene) {
              object.initialLeft = object.left - this.horizontalTranslation;
            } else {
              object.initialLeft = object.left - mx/this.scaleRatio;
            }
            object.initialTop = object.top - my;
          }
          object.set({
            left: object.initialLeft + mx/this.scaleRatio,
            top: object.initialTop + my
          });
          object.setCoords();
        });
        this.fabricCanvas.renderAll();
        if(beforeSave) this.horizontalTranslation = mx/this.scaleRatio;
      }
    },
    setAllObjectsSelectable(selectable) {
      if(!selectable) this.fabricCanvas.discardActiveObject();
      let objects = this.fabricCanvas.getObjects();
      objects.forEach(object => object.set('selectable', selectable));
      this.fabricCanvas.renderAll();
    },
    loadCurrentInsightDrawing() {
      let drawing = null;
      if(this.currentInsight?.sceneview_set.length > 0) {
        if(this.currentInsight.sceneview_set[0]?.drawings?.length > 0) {
          drawing = this.currentInsight.sceneview_set[0].drawings[0];
        }
      } else if(this.currentInsight?.imageview_set.length > 0) {
        if(this.currentInsight.imageview_set[0]?.drawings?.length > 0) {
          drawing = this.currentInsight.imageview_set[0].drawings[0];
        }
      }

      if(drawing) {
        this.loadFabricCanvas(drawing.drawing_json, drawing.width, drawing.height, drawing.horizontal_translation_factor);
        this.drawingName = drawing.name;
        this.setDrawingMode(true);
        this.drawingSaved = true;
      } else {
        this.resetDrawingAndTurnDrawingModeOff();
      }
    },
    resetDrawingAndTurnDrawingModeOff() {
      this.drawingSaved = false;
      this.drawingName = 'Drawing 1';
      this.setDrawingMode(false);
      this.fabricCanvas.clear();
      this.fabricCanvasWidth = 0;
      this.fabricCanvasHeight = 0;
      this.horizontalTranslation = 0;
    },
    ...mapActions({
      setDrawingMode: 'project/setDrawingMode',
      saveDrawingStore: 'project/saveDrawing',
      updateDrawing: 'project/updateDrawing',
      deleteDrawing: 'project/deleteDrawing'
    }),
  },
  watch: {
    layersPanelOpen() {
      this.scaleFabricCanvas();
      this.resizeFabricCanvas();
    },
    viewerMode(newValue, oldValue) {
      if(oldValue === 'Insights') {
        this.resetDrawingAndTurnDrawingModeOff();
      } else if (newValue === 'Insights') {
        this.loadCurrentInsightDrawing();
        this.resizeFabricCanvas();
        this.scaleFabricCanvas();
      }
    },
    currentInsight() {
      if(this.viewerMode == 'Insights') {
        this.loadCurrentInsightDrawing();
        setTimeout(() => {
          this.resizeFabricCanvas();
          this.scaleFabricCanvas();
        }, 100);
      }
    },
    sceneViewCompositionMode(newValue) {
      if(!newValue) {
        this.loadCurrentInsightDrawing();
      }
    },
    async drawingMode(newValue) {
      if(this.fabricCanvasHeight == 0 || this.fabricCanvasWidth == 0) {
        await this.$nextTick();
        this.fabricCanvasHeight = this.$refs.fabric_canvas_container.clientHeight;
        this.fabricCanvasWidth = this.$refs.fabric_canvas_container.clientWidth;
      }
      
      if(newValue) {
        this.turnSelectionModeOn();
        setTimeout(() => {
          this.resizeFabricCanvas();
          this.scaleFabricCanvas();
        }, 100);
      }
    },
    pencilColour(newValue) {
      let opacity = this.pencilOrLineIsHighlighter ? 0.5 : 1;
      this.fabricCanvas.freeDrawingBrush.color = `rgba(${newValue[0]}, ${newValue[1]}, ${newValue[2]}, ${opacity})`;
    },
    pencilLineWeight(newValue) {
      this.fabricCanvas.freeDrawingBrush.width = newValue;
    },
    lineColour(newValue) {
      let opacity = this.pencilOrLineIsHighlighter ? 0.5 : 1;
      this.lineColourRGB = `rgba(${newValue[0]}, ${newValue[1]}, ${newValue[2]}, ${opacity})`;
    },
    textColour(newValue) {
      this.textColourRGB = `rgb(${newValue[0]}, ${newValue[1]}, ${newValue[2]})`;
    },
    shapeBorderColour(newValue) {
      this.shapeBorderColourRGB = `rgba(${newValue[0]}, ${newValue[1]}, ${newValue[2]}, ${this.shapeBorderOpacity/100})`;
    },
    shapeBorderOpacity(newValue) {
      if (newValue != 100) this.shapeStyle = 'Custom';
      this.shapeBorderColourRGB = `rgba(${this.shapeBorderColour[0]}, ${this.shapeBorderColour[1]}, ${this.shapeBorderColour[2]}, ${newValue/100})`;
    },
    shapeFillColour(newValue) {
      this.shapeFillColourRGB = `rgba(${newValue[0]}, ${newValue[1]}, ${newValue[2]}, ${this.shapeFillOpacity/100})`;
    },
    shapeFillOpacity(newValue) {
      if ((newValue != 30 && this.shapeStyle == 'Highlight') 
        || (newValue != 0 && this.shapeStyle == 'Subtle')
        || (newValue != 100 && ['Bold', 'RWDI'].includes(this.shapeStyle)))
        this.shapeStyle = 'Custom';
      this.shapeFillColourRGB = `rgba(${this.shapeFillColour[0]}, ${this.shapeFillColour[1]}, ${this.shapeFillColour[2]}, ${newValue/100})`;
    },
    textBoxBorderColour(newValue) {
      this.textBoxBorderColourRGB = `rgba(${newValue[0]}, ${newValue[1]}, ${newValue[2]}, ${this.textBoxBorderOpacity/100})`;
    },
    textBoxBorderOpacity(newValue) {
      if(newValue != 100) this.textBoxStyle = 'Custom';
      this.textBoxBorderColourRGB = `rgba(${this.textBoxBorderColour[0]}, ${this.textBoxBorderColour[1]}, ${this.textBoxBorderColour[2]}, ${newValue/100})`;
    },
    textBoxFillColour(newValue) {
      this.textBoxFillColourRGB = `rgba(${newValue[0]}, ${newValue[1]}, ${newValue[2]}, ${this.textBoxFillOpacity/100})`;
    },
    textBoxFillOpacity(newValue) {
      if(newValue != 100) this.textBoxStyle = 'Custom';
      this.textBoxFillColourRGB = `rgba(${this.textBoxFillColour[0]}, ${this.textBoxFillColour[1]}, ${this.textBoxFillColour[2]}, ${newValue/100})`;
    }
  }
};
</script>

<style>
#drawing_toolbar {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #60717C;
  width: 100%;
  padding: 0.75rem;
  height: 2.375rem;
}

#drawing_toolbar_icons {
  margin-top: 0px;
  width: 84%;
  text-align: center;
}

.drawing-toolbar-trash-icon {
  cursor: pointer;
  color: white;
  vertical-align: middle !important;
}

#drawing_toolbar_buttons {
  position: absolute;
  right: 40px;
  top: 12px;
}

#fabric_canvas_container {
  width: calc(100% - 10px);
  height: calc(100% - 72px);
  position: absolute;
  top: 3.875rem;
  margin-top: 0px; 
  border: 5px solid #007bff;
}

#pencil_menu {
  position: absolute;
  background: white;
  left: 22.75vw;
  top: 3rem;
  z-index: 1;
  font-size: 0.7rem;
  color: var(--grey-700);
}

#line_menu {
  position: absolute;
  background: white;
  left: 27vw;
  top: 3rem;
  z-index: 1;
  font-size: 0.7rem;
  color: var(--grey-700);
}

#shape_menu {
  position: absolute;
  background: white;
  left: 31vw;
  top: 3rem;
  z-index: 1;
  font-size: 0.7rem;
  color: var(--grey-700);
}

#image_menu {
  position: absolute;
  background: white;
  left: 35vw;
  top: 3rem;
  z-index: 1;
  font-size: 0.7rem;
  color: var(--grey-700);
}

#text_menu {
  position: absolute;
  background: white;
  left: 39vw;
  top: 3rem;
  z-index: 1;
  font-size: 0.7rem;
  color: var(--grey-700);
}

.colour-choice {
  width: 1.2rem;
  height: 1.2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.selected-colour-choice {
  border: 2px rgb(77, 187, 255) solid;
  background-clip: content-box;
  padding: 1.6px;
}

.opacity-slider {
  width: 85%;
  vertical-align: middle;
}

.opacity-label {
  font-weight: bold;
}

.line-type-choice {
  cursor: pointer;
}

.line-weight-choice {
  width: 2.2rem;
  background-color: var(--grey-700);
  background-clip: content-box;
  cursor: pointer;
}

.selected-line-weight-choice {
  border: 2px rgb(77, 187, 255) solid !important;
}

.close-menu {
  cursor: pointer;
}

.drawing-dropdown button {
  background-color: white;
  font-size: 0.7rem;
  min-width: 2rem;
}

.drawing-dropdown li {
  overflow-x: hidden;
  font-size: 0.7rem;
}

.drawing-dropdown a {
  padding: 1px 8px;
  text-decoration: none;
  color: var(--grey-700);
}

.text-icon {
  font-size: 1.6rem;
  color: black;
  cursor: pointer;
}

.selected-text-icon {
  border: 1px rgb(77, 187, 255) solid !important;
}

.drawing-tool-image {
  cursor: pointer;
}

.selected-drawing-tool-image {
  border: 2px rgb(77, 187, 255) solid !important;
}

.text-box-sub-header {
  font-size: 0.9em;
  font-weight: normal;
}

.toggle-menu-expanded {
  cursor: pointer;
}

.gray-button {
  background-color: var(--grey-400);
}

.gray-button:hover {
  background-color: var(--grey-400);
}

.gray-button:focus {
  background-color: var(--grey-400);
}

.gray-button:active {
  background-color: var(--grey-400);
}

.black-icon {
  color: black;
}

.max-icon-size {
  max-height: 20px;
  max-width: 20px;
}

.create-insight-description-text {
  font-size: 14px;
  font-style: italic;
}
</style>
